body{
    font-family: 'MedievalSharp', cursive;
    /* font-weight: bold; */
}
::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
#mainBox{
    position:relative;
}
.mainNav{
    /* max-width: 1150px; */
    margin: 0 auto;
    /* background-color: whitesmoke !important; */
    padding: 1rem 2rem;
    background-color: rgba(245, 245, 245, 0.856) !important;

}

#mainAccountError{
    text-align: center;
    color:white;
    background-color: rgb(31, 98, 124);
    padding: 1rem;
}

.playButton{
    background-color: rgba(155, 87, 23, 0.8);
    padding: 1rem 2.5rem !important;
    font-size: 1.5rem;
    margin-left: 2rem;
}

.playButton:hover{
    background-color: rgba(192, 107, 27, 0.8);
}

.rulesButton{
    background-color: rgba(26, 112, 138, 0.8);
    padding: 1rem 2.5rem !important;
    font-size: 1.5rem;
    margin-left: 2rem;
    text-decoration: none !important;
}

.rulesButton a{
    color:white;
    text-decoration: none !important;
}

.rulesButton a:hover{
    text-decoration: none !important;
}

.rulesButton:hover{
    background-color: rgba(20, 83, 102, 0.8);
    text-decoration: none !important;
}

#mainLanding{
    width: 100%;
    margin: 0 auto;
    /* border: 1px solid red; */
    overflow: hidden;
    /* background-image: url("/images/gameMenuBackground.jpeg"); */
    /* background-color: rgba(112, 63, 17, 0.8) !important; */
    /* height: 50rem; */
    background-color: rgb(252, 238, 225);

}

.landingGeneral{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
}

.backgroundLightGray{
    background-color: rgba(240, 236, 236, 0.7);
}

.backgroundLightBrown{
    background-color: rgba(192, 107, 27, 0.8);
}

.landingGeneral h2 {
    font-weight: bold;
    color:white;
}

#backgroundImage {
    width: 100%;
}

.generalButton{
    background-color: rgba(245, 245, 245, 0.8);
    margin: 0 auto;
}

#gamePlayVideoBox{
    text-align: center;
    background-image: url("/images/gameMenuBackground.jpeg");
    /* opacity: 0.7; */
}

#gamePlayVideo{
    width: 70rem;
    /* height: 37rem; */
    -webkit-box-shadow: 0px 0px 3px 5px #f2e1f2;
    -moz-box-shadow: 0px 0px 3px 5px #f2e1f2;
    box-shadow: 0px 0px 70px 70px #f2e1f2; 
    border-radius: 20px;
    margin-bottom: 5rem;
}

#gameVideoTitle{
    font-weight: bold;
    margin: 4rem 0;
    color: rgb(255, 254, 254);
    font-size: 3rem;
    text-shadow: 0 0 6px rgb(0, 0, 0);
}

#charactersImage{
    width: 50rem;
    /* box-shadow: 0px 0px 70px 70px #f8f6f6;  */
}

.section{
    margin: 5rem 0;
    /* width: 80%;
    margin: 0 auto; */
}

#boardImage{
    width: 90%;
}

.landingPageImages{
    width: 30rem;
}

#discordBox{
    text-align: center;
    padding-top: 3rem;
}

#discordIFrame{
    width: 30rem;
    height: 20rem;
}

#discordBox h2{
    text-align: center;
    font-weight: bold;
    color:white;
}

/*GAME CREATION MENU*/
#gameMenuBackground{
    z-index: -1;
    width: 100%;
    background-image: url("/images/gameMenuBackground.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    /* border: 1px solid orange; */
    padding: 5rem;
    min-height: 40rem;
}

#bannerImageBox{
    background-image: url("/images/miscallaneous_assets/bannerImage.JPG");
    background-size:100%;
    background-repeat: no-repeat;
    /* padding: 10rem; */
    width: 100%;
    height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

#bannerImageText{
    color: white;
    background-color: rgba(207, 117, 32, 0.7);
}

#gameMenuContainer{
    background-color: rgba(245, 245, 245, 0.7);
    width: 60%;
    max-width: 700px;
    margin: 0 auto;
    padding: 3rem;
    text-align: center;
    position: relative;
}

.generalMenuBox{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    
}

.menuLabels{
    margin-right:1rem;
    padding: 0.25rem;
    background-color: whitesmoke;
    box-shadow: 0.1rem 0.1rem 0.5rem 0.1rem #888888;
}

.menuLabels:hover{
    background-color: rgba(235, 232, 232, 0.555);
    cursor: pointer;
}

#gameInvitesTitle{
    position: absolute;
    right: 0;
    padding: 1px 15px;
    background-color: rgb(14, 144, 153);
    box-shadow: 0.1rem 0.1rem 0.5rem 0.1rem #888888;
    color: white;
}

#gameInvitesTitleFixed{
    display: none;
}

#gameInvitesTitle:hover{
    background-color: rgb(13, 112, 119);
    transform: scale(1.1);
    cursor: pointer;
}


#onlineListContainer{
    position: fixed;
    top: 10px;
    right: 10px;    
    z-index: 100;
}

.counterContainer {
    position:absolute;
    right:-10px;
    top:-10px;
    background-color: crimson;
    border-radius: 50%;
    width: 25px;
    padding: 1px;
    border: 2px solid rgb(61, 6, 17);
    text-align: center;
}

.chatCounter {
    position:absolute;
    right: -10px;
    top: -10px;
    background-color: rgb(20, 190, 220);
    border-radius: 50%;
    width: 25px;
    padding: 1px;
    border: 2px solid rgb(6, 63, 73);
    text-align: center;
}

.onlineSymbol {
    width: 20px;
}

#chatAndInvitesBox {
    position: fixed;
    top: 10px;
    left: 3px;    
    z-index: 100;
    width: max-content;
    padding: 5px 0;
}

#chatTitle{
    background-color: rgba(107, 11, 30, 0.7);
    font-weight: bold;
    color: white;
    padding: 2.5px 3px;
    border-radius: 10px;
    border: solid 3px whitesmoke;
    width: 100px;
    position: relative;
    margin-bottom: 10px;
}

#chatTitle:hover{
    cursor: pointer;
    transform: scale(1.1);
}

#onlineListTitle{
    background-color: #128D15;
    font-weight: bold;
    color: white;
    padding: 3px;
    border-radius: 10px;
    border: solid 3px whitesmoke;
    width: 200px;
    position: absolute;
    right: 0;
    text-align: center;
}

#onlineListTitle:hover{
    background-color: #0d720f;
    cursor: pointer;
}

#onlineListBox{
    width: 250px;
    padding: 10px;
    background-color: rgba(245,245,245, 0.7);
    margin-top: 40px;
    overflow-y: scroll;
    max-height: 30rem;
}

.onlineListElement{
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0.1rem 0.1rem 0.5rem 0.1rem #888888;
    background-color: white;
    justify-content: space-between;
}

.onlineListElementLeft {
    display: flex;
    flex-direction: column;
}
.onlineListElementLeft img {
    vertical-align: baseline;
    margin-right: 5px;
}

.onlineListElement h4{
    margin: 0;
}

.swordsIcon {
    width: 30px;
    /* border: 1px solid crimson;
    border-radius: 50%;
    box-shadow: 0.1rem 0.1rem 0.5rem 0.1rem #888888; */
}

.swordsIcon:hover {
    transform: scale(1.2);
    cursor: pointer;
}

#inGameText {
    color: crimson;
    font-weight: bold;
}

#challengeSentText {
    color: rgb(35, 161, 245);
    font-weight: bold;
}

#newChallengeText {
    color: crimson;
}

#profileBox{
    position: absolute;
    z-index: 10;
    background-color: whitesmoke;
    box-shadow: 0.1rem 0.1rem 0.5rem 0.1rem #888888;
    width: 100%;
    margin: 0 auto;
    left: 0;
}

#profileInnerBox {
    font-weight: lighter !important;
    overflow-y: scroll;
    height: 20rem;
}

#gameInvitesContainer {
    padding: 10px;
}

@keyframes zoomChallenge {
    0% {
        transform: scale(1,1);
    }
    50% {
        transform: scale(1.1,1.1);
    }
    100% {
        transform: scale(1,1);
    }
}

.gameInviteBox {
    padding: 10px;
    background-color: whitesmoke;
    box-shadow: 0.1rem 0.1rem 0.5rem 0.1rem #888888;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    animation: zoomChallenge 1s 3 ease-in-out;
}

.gameInviteInnerBox {
    display: flex;
    flex-direction: column;
}

#acceptButton {
    background-color: rgba(25, 145, 201, 0.7);
    color: white;
    box-shadow: 0.1rem 0.1rem 0.5rem 0.1rem #888888;
    padding: 0rem 0.5rem;
    margin: 3px;
}

#declineButton {
    background-color: crimson;
    color: white;
    box-shadow: 0.1rem 0.1rem 0.5rem 0.1rem #888888;
    padding: 0rem 0.5rem;
    margin: 3px;
}

#acceptButton:hover {
    transform: scale(1.1);
    cursor: pointer;
}

#declineButton:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.wins{
    background-color: rgb(18, 136, 18);
    padding: 0.25rem;
    color:white;
    border-radius: 10px;
}

.losses{
    background-color: rgb(172, 26, 26);
    padding: 0.25rem;
    color:white;
    border-radius: 10px;
}

.draws{
    background-color: rgb(129, 129, 129);
    padding: 0.25rem;
    color:white;
    border-radius: 10px;
}

#leaderTable{
    width: 100%;
    margin: 1rem;
    text-align: center;
}

.leaderboardPlayerHighlight{
    background-color: rgb(138, 226, 255);
}

#gameMenuContainer h1 {
    color:rgb(153, 138, 8);
    text-decoration: underline;
    font-weight: 800;
    margin-top: 1rem;
    /* background-color:  */
}

.gameMenuInnerContainer{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
}

#menuDivider{
    margin: 0 3rem;
    padding: 2rem 0.5rem;
    background-color: rgba(97, 91, 91, 0.548);
    border-radius: 10px;
}

.menuButtons{
    border: none;
    border-radius: 10px;
    color: white;
    padding: 1rem;
    display: block;
    margin: 0 auto;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.createGame{
    background-color: rgba(145, 22, 22, 0.993);
}

.dropDown{
    background-color: rgba(9, 101, 138, 1);
}

.createGame:hover{
    background-color: rgba(109, 17, 17, 0.993);
}

.deleteGame{
    background-color: rgba(145, 22, 22, 0.993);
    color:white;
}

.deleteGame:hover{
    background-color: rgba(109, 17, 17, 0.993);
    cursor: pointer;
}

.leaveGame{
    background-color: rgba(145, 22, 22, 0.993);
}

.leaveGame:hover{
    background-color: rgba(109, 17, 17, 0.993);
}

.closeCardInfo{
    background-color: rgba(145, 22, 22, 0.993);
}

.closeCardInfo:hover{
    background-color: rgba(109, 17, 17, 0.993);
}

.logout{
    margin-top: 2rem;
    background-color: rgba(141, 138, 138, 0.993);
}

.logout:hover{
    background-color: rgba(158, 150, 150, 0.993);
}

.findMatch{
    background-color: rgba(9, 101, 138, 0.7);
    font-size: 1rem;
    padding: 1rem 3rem;
}

.findMatch:hover{
    background-color: rgba(6, 64, 87, 0.7);
    font-size: 1rem;
    padding: 1rem 3rem;
}

.viewLobby{
    background-color: rgba(9, 101, 138, 0.7);
    font-size: 1rem;
    padding: 1rem 3rem;
}

.viewLobby:hover{
    background-color: rgba(6, 64, 87, 0.7);
    font-size: 1rem;
    padding: 1rem 3rem;
}

.playAI{
    background-color: rgba(231, 136, 12, 0.904);
    font-size: 1rem;
    padding: 1rem 3rem;
}

.playAI:hover{
    background-color: rgba(231, 136, 12, 0.637);
    font-size: 1rem;
    padding: 1rem 3rem;
}

#publicGameBox{
    max-height: 25rem;
    padding: 1rem;
    overflow-y: scroll; 
}

.publicGameContainer{
    display: flex;
    flex-direction: column;
    background-color: whitesmoke;
    border-radius: 10px;
    box-shadow: 0.1rem 0.1rem 0.5rem 0.1rem #888888;
    margin: 0.5rem 0;
}

.publicGameInnerContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.rankedLabel{
    background-color: rgba(145, 22, 22, 0.993);
    color: white;
    box-shadow: 0.1rem 0.1rem 0.5rem 0.1rem #888888;
    padding: 0rem 0.5rem;
    width: 20%;
}

.casualLabel{
    background-color: rgba(26, 107, 10, 0.7);
    color: white;
    box-shadow: 0.1rem 0.1rem 0.5rem 0.1rem #888888;
    padding: 0rem 0.5rem;
    width: 20%;
}

.rankedLabelInvite{
    background-color: rgba(145, 22, 22, 0.993);
    color: white;
    box-shadow: 0.1rem 0.1rem 0.5rem 0.1rem #888888;
    padding: 0rem 0.5rem;
}

.casualLabelInvite{
    background-color: rgba(26, 107, 10, 0.7);
    color: white;
    box-shadow: 0.1rem 0.1rem 0.5rem 0.1rem #888888;
    padding: 0rem 0.5rem;
}

.numPlayers{
    margin: 0 1rem;
}

.hostLabel{
    margin: 0 1rem;
}

.joinGame{
    background-color: rgba(26, 107, 10, 0.7);
}

.joinGame:hover{
    background-color: rgba(18, 73, 7, 0.7);
}

.startGame{
    background-color: rgba(26, 107, 10, 0.7);
}

.startGame:hover{
    background-color: rgba(18, 73, 7, 0.7);
}

#joinGameInput{
    padding: 0.5rem 1rem;
    border: none;
    text-align: center;
    /* border-radius: 10px; */
}

#findMatchMenu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20rem;
    height: 20rem;
    border: 1.5rem solid rgba(9, 101, 138, 0.7);
    border-radius: 50%;
    margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 1rem;
    background-color: rgba(10, 145, 199, 0.3);
}

#findMatchMenu h3 {
    color: rgba(3, 37, 51, 0.7);
    bottom: 1rem;
}

#queueTime {
    color: rgba(3, 37, 51, 0.7);
    font-size: 3rem;
    font-weight: bold;
}

#useTimerBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0.25rem;
}

.useTimerBoxInner{
    margin: 0.5rem;
    font-weight: bold;
}

#playerBox{
    background-color: rgba(245, 245, 245, 0.7);
    width: 40%;
    margin: 0 auto;
    padding: 1rem;
}

.authBox{
    margin: 0 auto;
    width: 100%;
    padding: 1.5rem;
    box-shadow: 0.1rem 0.1rem 0.5rem 0.1rem #888888;
    border-radius: 7px;
    background-color: white;
    position:relative;
}

.authButtons{
    width: 100%;
}

#forgotPassButton{
    color: #025569;
}

.forgotPassBox{
    /* box-shadow: 1px 1px 10px 0px #888888; */
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
    padding: 0.6rem; 
}

#forgotPassButton:hover{
    cursor: pointer;
}


.brownButton{
    background-color: #c56133;
    color:white;
    z-index: 10;
}

.brownButtonDisabled{
    background-color: #c56133;
    color:white;
    cursor: default;
}

.brownButtonDisabled:hover{
    background-color: #c56133;
    color:white;
    cursor: default;
}

.brownButton:hover{
    background-color: #9e4f2a;
    color:white;
}

.successMessage{
    color: green;
    font: verdana;
    font-weight: bold;
    margin: 5px 0;
}

.loginButtons{
    margin: 0 auto;
}

.loginButton{
    border: 0.15rem solid #df8468;
    margin: 0.6rem;
}

.loginButton:hover{
    background-color: rgba(22, 153, 186,0.1);
    cursor:pointer;
}

.signUpButton{
    border: 0.15rem solid #dd613b;
    margin: 0.6rem;
}

.signUpButton:hover{
    background-color: rgba(17, 115, 139, 0.1);
    cursor:pointer;
}

/*GAME RESULTS and SHOW INFO*/

#gameResultsBox{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 50rem;
    padding: 5rem;
    z-index: 101;
    background-color: rgba(255, 255, 255, 0.8);
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

#cardInfoBox{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 20rem;
    padding: 1rem;
    z-index: 101;
    background-color: rgba(255, 255, 255, 0.8);
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

#cardInfoInnerBox{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.infoCard{
    width: 10rem !important;
}

#cardInfoTextBox{
    font-size: 1rem;
    width: 60%;
    font-weight: bold;
    padding: 2rem;
}

#gameResultsTitle{
    margin-top: 3rem;
    text-decoration: underline;
}

.resultsBox{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.resultsProperty{
    margin: 0 1rem;
}

.winResult{
    color: rgb(5, 168, 5);
}

.lossResult{
    color: crimson;
}

/*GAME STYLES*/

#gameContainer{
    width: 100%;
    max-width: 1150px;
    margin: 0 auto;
    /* border: 2px solid blue; */
}

#gameBackground{
    z-index: -1;
    width: 100%;
    background-image: url("/images/gameBackground.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    /* border: 1px solid orange; */
}

#actionBox{
    width: 100%;
    max-width: 1150px;
    min-height: 5.5rem;
    /* border: 3px solid red; */
    padding: 2.5rem 0 1rem 0;
    /* -webkit-box-shadow: 5px 5px 5px 1px rgb(83, 80, 80);  */
    font-family: 'MedievalSharp', cursive;
    position: fixed;
    top: 0;
    text-align: center;
    z-index: 100;
}

#actionBox::before{
    content: "";
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    height: 100%;
    background-image: url("/images/miscallaneous_assets/actionBackground.png");
    opacity: 0.7;
}

#actionBox h2{
    position: relative;
}

#playerNameAction{
    font-weight: bold;
}

.boardTitle{
    margin: 0 auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.boardTitleName{
    font-weight: bold;
    text-decoration: underline;
}

#boardBox{
    width: 100%;
    /* border: 3px solid red; */
    padding: 5.5rem 0;
    /* min-height: 25rem; */
    background-image: url("/images/miscallaneous_assets/boardBackground.jpg");
    position: relative;
}

.boardActionButton{
    padding: 1rem 2rem;
    font-size: 1rem;
    margin: 1rem 2rem;
    border: none;
    border-radius: 10px;
}

.boardActionButton:hover{
    background-color: rgb(224, 214, 214);
}


#boardActionBox{
    width: 70%;
    margin: 0 auto;
    background-color: rgba(155, 5, 5, 0.7);
    padding: 1rem;
    text-align: center;
    position:absolute;
    z-index: 5;
}

#boardActionInnerBox{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    overflow-x: scroll;
    overflow-y: hidden;
}

#boardActionBoxTitle{
    color:white;
}

.characterChoices{
    background-color: whitesmoke;
    padding: 1rem;
    margin: 0 0.5rem;
    border-radius: 10px;
}

.characterChoices:hover{
    transform: scale(1.05);
    cursor: pointer;
}

.tradingPileCards{
    margin: 0 0.5rem;
    max-width: 14rem;
    min-width: 10rem;
}

.tradingPileCardsHighlighted{
    margin: 0 0.5rem;
    border: 5px solid gold;
    max-width: 14rem;
    min-width: 10rem;
}

.tradeHandCard{
    width: 100%;
    background-color: black;
    padding: 2px;
}

.tradeButton{
    background-color: whitesmoke;
    padding: 1rem;
    margin: 0 0.5rem;
    border-radius: 10px;
    width: 30%;
    margin: 0 auto;
}

.tradeButton:hover{
    background-color: rgb(206, 203, 203);
    cursor: pointer;
}

.choiceSeperator{
    background-color: lightgray;
    width: 0.5rem;
    height: 5rem;
    margin: 0 1rem;
    border-radius: 10px;
}

#boardInnerBox{
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 auto;
    padding: 1rem 0rem;
    min-height: 20rem;
}

#boardTitleBox{
    width: 100%;
    text-align: center;
}

#boardContainer{
    display: flex;
    flex-direction: row;
}

/* .boardColorBox{
    width: 10rem;
} */

.boardInnerContainer{
    margin: 0 1rem;
    display: flex;
    position:relative;
    flex-direction: row;
    align-items: center;
    min-width:8rem;
    max-width: 12rem;
}

.boardCard{
    width: 100%;
    background-color: black;
    padding: 2px;
}

.boardCard:hover{
    transform: scale(1.05);
    cursor:pointer;
    /* -webkit-box-shadow: 5px 5px 5px 1px rgb(83, 80, 80); 
    box-shadow: 5px 5px 5px 1px rgb(109, 107, 107); */
}

#leftBoard{
    padding:0;
    /* border: 1px solid red; */
}

#rightBoard{
    padding:0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

#rightTopBoard{
    text-align: center;
    margin-bottom: 1rem;
}

#endTurnButton{
    background-color: rgba(220, 20, 60, 0.7);
    color: white;
    border:none;
    font-size: 1.5rem;
    border-radius: 10px;
}

#endTurnButton:hover{
    background-color: rgba(160, 17, 46, 0.7);
}

#timerBoxGreen{
    background-color: rgba(255, 255, 255, 0.2);
    padding: 0.5rem;
    min-width: 5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color:rgb(76, 146, 43);
    border: 5px solid rgba(192, 107, 27, 0.8);

}

#timerBoxYellow{
    background-color: rgba(255, 255, 255, 0.2);
    padding: 0.5rem;
    min-width: 5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color:rgb(192, 148, 4);
    border: 5px solid rgba(192, 107, 27, 0.8);
}

#timerBoxRed{
    background-color: rgba(255, 255, 255, 0.2);
    padding: 0.5rem;
    min-width: 5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color:rgb(175, 49, 49);
    border: 5px solid rgba(192, 107, 27, 0.8);
}
.arrowButtons{
    font-size: 2rem;
    margin: 1rem 0.5rem;
    border-radius: 10px;
    padding: 0 1rem;
    background-color: rgba(247, 242, 242, 0.7);
    color: darkslategray;
    border:none;
}

.arrowButtons:hover{
    background-color: rgba(201, 196, 196, 0.7);
}

#goldDisplay{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#boardGoldImage{
    width: 3rem;
}

#boardCharacterImage{
    width: 1.5rem;
}



/*INFO BOX*/

#infoBox{
    width: 100%;
    padding: 0.5rem 0;
    background-image: url("/images/miscallaneous_assets/boardBackground.jpg");
}

#turnOrderBox{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
}

#turnOrderInnerElement{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

#turnOrderArrow{
    width: 1.2rem;
    position: absolute;
    margin-top: -2.5rem;
}

#turnOrderTitle{
    font-weight: bold;
    margin: 0 1rem;
}

#turnOrderElementName{
    background-color: rgba(192, 107, 27, 0.8);
    color: white;
    padding: 0.5rem 0.75rem;
    border-radius: 5px;
    font-weight: bold;
}

.turnOrderSelectedElement{
    position:relative;
    text-align: center;
    display: inline-block;
    margin-right: 0.25rem;
}

.turnOrderElement{
    display: inline-block;
    background-color: rgba(192, 107, 27, 0.2);
    padding: 0.5rem 0.75rem;
    border-radius: 5px;
    margin-right: 0.25rem;
}

#infoContainer{
    width: 100%;
    margin: 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    position: relative;
    /* overflow-x: scroll; */
    text-align: center;
}

#infoInnerContainer{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    overflow-x: scroll;
}

.infoInnerBox{
    text-align: center;
    margin: 0 1rem;
}

.infoInnerBox h6 {
    font-weight: bold;
    font-size: 1rem;
    width: 90%;
    margin: 0 auto;
}

#infoGoldContainer{
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

#infoGoldContainer h2{
    position: absolute;
    font-weight: bold;
    margin-top: 0.5rem;
    padding: 0rem 0.75rem;
    background-color: rgba(245, 245, 245, 0.5);
    border-radius: 50px;
}

#districtDeckSize{
    font-size: 1.5rem;
}

#goldContainer{
    margin: 1rem 0;
    text-align: center;
    /* border: 1px solid red; */
}

#goldContainer h5 {
    font-weight: bold;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

#discardPileButton{
    border: none;
    background-color: rgba(155, 5, 5, 0.6);
    color: white;
    border-radius: 10px;
}


#discardPileButton:hover{
    background-color: rgba(155, 5, 5, 0.8);
    cursor: pointer;
}

#goldValue {
    font-size: 1.8rem;
    margin: 0 0.5rem;
}

#goldImage{
    width: 5rem;
}

#districtDeckImage{
    width: 12rem;
}

#kingTokenImage{
    width: 8rem;
    margin: 1rem;
}

/*HAND*/

#handBox{
    /* border: 3px solid red; */
    padding: 1rem 0;
    background-color: rgba(255, 255, 255, 0.5);
}

#handTitle{
    text-align: center;
}

#handCardsBox{
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    width: 90%;
    /* justify-content: center; */
    margin: 0 auto;
    overflow-x: scroll;
    overflow-y: hidden;
}

.handCardBox{
    margin: 0 5px;
    display: flex;
    position:relative;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: 10rem;
    max-width:14rem;
}

#districtChooseBox{
    position: absolute;
    z-index: 3;
}

.handCard{
    width: 100%;
    background-color: black;
    padding: 2px;
}

.handCard:hover{
    transform: scale(1.05);
    cursor:pointer;
    -webkit-box-shadow: 5px 5px 5px 1px rgb(83, 80, 80); 
    box-shadow: 5px 5px 5px 1px rgb(109, 107, 107);
}

/*CHARACTER CARDS*/
#characterCardContainerOuter {
    overflow: scroll;
}

#characterCardContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    padding: 1rem;
    justify-content: left;
    overflow: scroll;
    width: max-content;
}

.characterCardBox{
    margin: 0 10px;
    width: max-content;
    position:relative;
    display: flex;
    align-items: center;
}

.characterCardBox:hover{
    transform: scale(1.05);
    box-shadow: 5px 5px 5px 1px rgb(109, 107, 107);
    cursor: pointer;
}

.currCharacterCardBox{
    margin: 0 auto;
    width: max-content;
    position:relative;
    display: flex;
    align-items: center;
}


#characterChooseBox{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 3;
    width: 100%;
    margin: 0 auto;
}

#characterInfoImage{
    width: 1rem;
}

#characterInfoButton{
    margin: 1px;
    border-radius: 30px;
    margin: 0 auto;
}

#chooseCharacterButton{
    margin: 1px;
    border-radius: 10px;
}

#characterInfoButton:hover{
    background-color: lightslategray;
}

#chooseCharacterButton:hover{
    background-color: lightslategray;
}

.characterCard{
    /* width: 100%; */
    max-width: 8.5rem;
}

#characterDivider{
    background-color: rgb(36, 27, 16);
    z-index: 5;
    padding: 7rem 0.25rem;
    border-radius: 10px;
    margin: 0 1rem;
}

#toggleCharactersButtonBox{
    width: 100%;
    text-align: center;
}

.toggleCharactersButton{
    padding: 0.5rem 1rem;
    border-radius: 10px;
    border: none;
    margin: 1rem;
} 

/*Special Purple Cards*/

#hauntedCityBox{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#discardPileBox{
    position: relative;
    display:flex;
    justify-content: center;
    align-items: center;
}

#chooseCardButton{
    position: absolute;
}

#chooseColorButton{
    color:white;
    font-size: 1rem;
    font-weight: bold;
}

.colorButton{
    padding: 0.75rem;
    border-radius: 50px;
}

.colorButton:hover{
    transform: scale(1.05);
}

/*Game Log & Chat*/

#gameOptionsBox{
    padding-bottom: 1rem;
    margin-top: 2rem;
}

#gameOptionsInnerBox{
    text-align: center;
}

.gameOptionButton{
    border: none;
    color:white;
    background-color: transparent;
}

.gameLogButtonBox{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: none;
    color:white;
    background-color: rgba(220, 20, 60, 0.9);
    padding: 0.5rem;
    margin: 0 auto;
}


.chatButtonBox{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: none;
    color:white;
    background-color: rgba(220, 20, 60, 0.7);
    margin: 0 auto;
}


.gameLogButtonBox:hover{
    background-color: rgba(173, 17, 49, 0.7);
    cursor: pointer;
}

#gameLogBox{
    position: fixed;
    text-align: left;
    /* min-width: 20rem;
    max-width: 30rem; */
    width: 20rem;
    word-wrap: break-word;
    z-index:99;
    background-color: rgba(239, 165, 94, 0.8);
    border-radius: 10px;
    padding: 1rem;
    right: 0;
    bottom: 5rem;
    max-height: 20rem;
    overflow-y: scroll;
}

#gameLogBox h4{
    text-decoration: underline;
    font-weight: bold;
}

#gameChatBox{
    position: fixed;
    text-align: left;
    /* min-width: 20rem;
    max-width: 30rem; */
    width: 20rem;
    word-wrap: break-word;
    z-index:101;
    background-color: rgba(239, 165, 94, 0.8);
    border-radius: 10px;
    padding: 1rem;
    left: 0;
    top: 50px;
    max-height: 20rem;
    overflow-y: scroll;
}

#gameChatBox h4{
    text-decoration: underline;
    font-weight: bold;
}


#logImage{
    width: 1.5rem;
    margin-right: 0.5rem;
}

#chatImage{
    width: 1.3rem;
    margin-right: 0.5rem;
}

#chatInput{
    width: 100%;
    border-radius: 10px;
    border: none;
    margin: 0.5rem 0;
}

.messageBox{
    display: flex;
    flex-direction: row;
    margin: 0.25rem 0;
}

/*Toggle*/

.react-toggle {
    touch-action: pan-x;
  
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
  
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }
  
  .react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  .react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
  }
  
  .react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: #4D4D4D;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  
  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #000000;
  }
  
  .react-toggle--checked .react-toggle-track {
    background-color: #19AB27;
  }
  
  .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #128D15;
  }
  
  .react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }
  
  .react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }
  
  .react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }
  
  .react-toggle--checked .react-toggle-track-x {
    opacity: 0;
  }
  
  .react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid #4D4D4D;
    border-radius: 50%;
    background-color: #FAFAFA;
  
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }
  
  .react-toggle--checked .react-toggle-thumb {
    left: 27px;
    border-color: #19AB27;
  }
  
  .react-toggle--focus .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
    -moz-box-shadow: 0px 0px 3px 2px #0099E0;
    box-shadow: 0px 0px 2px 3px #0099E0;
  }
  
  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
    -moz-box-shadow: 0px 0px 5px 5px #0099E0;
    box-shadow: 0px 0px 5px 5px #0099E0;
  }

  
  
  /* MEDIA QUERIES*/

/* 
  @media screen and (max-width: 600px) {

  } */

  @media screen and (max-width: 1200px) {

    /*Landing*/
    .mainNav{
        padding: 0.25rem;    
    }
    #gameVideoTitle{
        margin-top: 1rem;
        margin-bottom: 2.5rem;
        font-size: 2.5rem;
    }
    #gamePlayVideoBox{
        text-align: center;
        background-image: url("/images/gameMenuBackground.jpeg");
        /* opacity: 0.7; */
    }
    
    #gamePlayVideo{
        width: 50rem;
        /* height: 37rem; */
        -webkit-box-shadow: 0px 0px 3px 3px #f2e1f2;
        -moz-box-shadow: 0px 0px 3px 3px #f2e1f2;
        box-shadow: 0px 0px 70px 70px #f2e1f2; 
        border-radius: 20px;
        margin-bottom: 5rem;
    }

    #discordIFrame{
        width: 20rem;
        height: 20rem;
    }

    #boardImage{
        width: 100%;
    }
    
    #bannerImageBox{
        display:none;
    }

    #gameInvitesTitle{
        display: none;
    }

    #gameInvitesTitleFixed{
        display: block;
        padding: 1px 15px;
        background-color: rgb(14, 144, 153);
        box-shadow: 0.1rem 0.1rem 0.5rem 0.1rem #888888;
        color: white;
        position: relative;
    }
    
    #gameInvitesTitleFixed:hover{
        background-color: rgb(13, 112, 119);
        transform: scale(1.1);
        cursor: pointer;
    }
    
    /* Menu */
    #gameMenuBackground{
        padding: 1rem;
    }
    #gameMenuContainer{
        margin-top: 80px;
        width: 60%;
        padding: 1rem;
    }
    /* #gameMenuContainer h1{
        font-size: 2.5rem;
    } */
    #playerBox{
        width: 40%;
    }
    #findMatchMenu{
        width: 15rem;
        height: 15rem;
    }
    /*Game*/
    #goldValue{
        font-size: 1.5rem;
    }

    #actionBox{
        padding: 3rem 0 1rem 0;
    }
    
    #boardBox{
        padding: 6rem 0;
    }
    .characterCard{
        max-width: 7.5rem;
    }
  }

  @media screen and (max-width: 991px) {
    #rightBotBoard{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    #boardBox{
        padding-bottom: 2rem;
    }
  }

  @media screen and (max-width: 900px) {

    /*Landing*/
    .mainNav{
        padding: 0.25rem;    
    }
    #logoImg{
        width: 15rem;
    }
    .navbar{
        flex-direction: row;
    }
    .playButton{
        padding: 1rem !important;
        font-size: 1rem;
        margin-left: 0.5rem;
    }
    .rulesButton{
        padding: 1rem !important;
        font-size: 1rem;
        margin-left: 0.5rem;
    }
    #gameVideoTitle{
        margin-top: 1rem;
        margin-bottom: 2.5rem;
        font-size: 2.5rem;
    }
    #gamePlayVideoBox{
        text-align: center;
        background-image: url("/images/gameMenuBackground.jpeg");
        /* opacity: 0.7; */
    }
    
    #gamePlayVideo{
        width: 40rem;
        /* height: 37rem; */
        -webkit-box-shadow: 0px 0px 3px 3px #f2e1f2;
        -moz-box-shadow: 0px 0px 3px 3px #f2e1f2;
        box-shadow: 0px 0px 70px 70px #f2e1f2; 
        border-radius: 20px;
        margin-bottom: 5rem;
    }

    #discordIFrame{
        width: 20rem;
        height: 20rem;
    }

    #boardImage{
        width: 100%;
    }
    
    #bannerImageBox{
        display:none;
    }
    
    /* Menu */
    #gameMenuBackground{
        padding: 1rem;
    }
    #gameMenuContainer{
        width: 80%;
        padding: 1rem;
    }
    /* #gameMenuContainer h1{
        font-size: 2.5rem;
    } */
    #playerBox{
        width: 40%;
    }
    #findMatchMenu{
        width: 15rem;
        height: 15rem;
    }
    /*Game*/
    #infoInnerContainer{
        justify-content: left;
    }
    #goldValue{
        font-size: 1.25rem;
    }
    #cardInfoBox{
        width: 70%;
        height: 22rem;
        padding: 1rem;
    }
    .infoCard{
        width: 10rem !important;
    }
    #cardInfoTextBox{
        font-size: 1rem;
        width: 60%;
        font-weight: bold;
        padding: 2rem;
    }
    #boardActionInnerBox{
        justify-content: left;
    }
    
    #actionBox{
        padding: 3rem 0 1rem 0;
    }
    #boardBox{
        padding: 4rem 0;
    }
    #actionBox{
        padding: 3rem 0 1rem 0;
    }
    #boardBox{
        padding-top: 7rem;
        padding-bottom: 1rem;
    }
    .characterCard {
        width: 9rem;
    }
  }

  @media screen and (max-width: 767.5px) {

    /*Landing*/
    .mainNav{
        padding: 0.25rem;    
    }
    #logoImg{
        width: 12rem;
    }
    .navbar{
        flex-direction: row;
    }
    .playButton{
        padding: 0.75rem !important;
        font-size: 1rem;
        margin-left: 0.5rem;
    }
    .rulesButton{
        padding: 0.75rem !important;
        font-size: 1rem;
        margin-left: 0.5rem;
    }
    #gameVideoTitle{
        margin-top: 1rem;
        margin-bottom: 2.5rem;
        font-size: 2.5rem;
    }
    #gamePlayVideoBox{
        text-align: center;
        background-image: url("/images/gameMenuBackground.jpeg");
        /* opacity: 0.7; */
    }
    
    #gamePlayVideo{
        width: 30rem;
        /* height: 37rem; */
        -webkit-box-shadow: 0px 0px 3px 3px #f2e1f2;
        -moz-box-shadow: 0px 0px 3px 3px #f2e1f2;
        box-shadow: 0px 0px 70px 70px #f2e1f2; 
        border-radius: 20px;
        margin-bottom: 5rem;
    }

    #discordIFrame{
        width: 20rem;
        height: 20rem;
    }

    #boardImage{
        width: 100%;
    }
    
    #bannerImageBox{
        display:none;
    }
    
    /* Menu */
    #gameMenuBackground{
        padding: 1rem;
    }
    #gameMenuContainer{
        width: 100%;
        padding: 1rem;
    }
    /* #gameMenuContainer h1{
        font-size: 2.5rem;
    } */
    #playerBox{
        width: 40%;
    }
    #findMatchMenu{
        width: 15rem;
        height: 15rem;
    }
    /*Game*/
    #actionBox h2{
        font-size: 1.5rem;
        padding: 0;
    }
    .boardTitle{
        font-size: 2rem;
    }
    #boardBox{
        padding-top: 6rem;
        padding-bottom: 0rem;
    }
    /* #boardInnerBox{
        padding-top: 2rem;
    } */
    .boardInnerContainer{
        min-width:5rem;
        max-width: 8rem;
    }
    #leftBoard{
        min-height: 12rem;
        overflow-x: scroll;
        /* overflow-y: scroll; */
    }
    #rightBotBoard{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    #deckBox{
        display:none;
    }
    .currCharacterCardBox{
        /* width: 7rem; */
    }
    #infoInnerContainer{
        justify-content: center;
    }
    #infoGoldImage{
        width: 5rem;
    }
    #infoGoldContainer h2 {
        font-size: 2rem;
    }
    #goldImage{
        width: 5rem;
    }
    #goldValue{
        font-size: 1.25rem;
    }
    
    #districtDeckImage{
        width: 4rem;
    }
    
    #kingTokenImage{
        width: 4rem;
    }
    .toggleCharactersButton{
        font-size: 1rem;
    }
    #characterCardContainer{
        overflow: scroll;
    }
    .characterCard{
        width: 7rem;
    }
    #gameOptionsBox{
        padding: 1rem 0;
        margin-top: 0;
        background-color: rgb(219, 214, 214,0.8);
    }
    .arrowBoxLabels{
        display:none;
    }
    .colon{
        margin-left: 0.25rem;
    }
    #discardPileButton{
        display:none;
    }
    #turnTimerTitle{
        display:none;
    }
    #timerBoxGreen{
        min-width: 6rem;
    }

    #timerContainer{
        margin-top: 1rem;
    }
    
    #timerBoxYellow{
        min-width: 6rem;
    }
    
    #timerBoxRed{
        min-width: 6rem;
    }

    #cardInfoBox{
        width: 70%;
        height: 20rem;
        padding: 1rem;
    }
    .infoCard{
        width: 8rem !important;
    }
    #cardInfoTextBox{
        font-size: 1rem;
        width: 60%;
        font-weight: bold;
        padding: 2rem;
    }

    /*Results*/
    .resultsProperty{
        font-size: 2rem;
    }
    #actionBox{
        padding: 3rem 0 1rem 0;
    }
  }

  @media screen and (max-width: 550px) {

    /*Landing*/
    .mainNav{
        padding: 0.25rem;    
    }
    #logoImg{
        width: 12rem;
    }
    .navbar{
        flex-direction: row;
    }
    .playButton{
        padding: 0.75rem !important;
        font-size: 1rem;
        margin-left: 0.5rem;
    }
    .rulesButton{
        padding: 0.75rem !important;
        font-size: 1rem;
        margin-left: 0.5rem;
    }
    #gameVideoTitle{
        margin-top: 1rem;
        margin-bottom: 2.5rem;
        font-size: 2.5rem;
    }
    #gamePlayVideoBox{
        text-align: center;
        background-image: url("/images/gameMenuBackground.jpeg");
        /* opacity: 0.7; */
    }
    
    #gamePlayVideo{
        width: 25rem;
        /* height: 37rem; */
        -webkit-box-shadow: 0px 0px 3px 3px #f2e1f2;
        -moz-box-shadow: 0px 0px 3px 3px #f2e1f2;
        box-shadow: 0px 0px 70px 70px #f2e1f2; 
        border-radius: 20px;
        margin-bottom: 5rem;
    }

    #discordIFrame{
        width: 20rem;
        height: 20rem;
    }

    #boardImage{
        width: 100%;
    }
    
    #bannerImageBox{
        display:none;
    }
    
    /* Menu */
    #gameMenuBackground{
        padding: 1rem;
    }
    #gameMenuContainer{
        width: 100%;
        padding: 1rem;
        margin-top: 100px;
    }
    #gameMenuContainer h5{
        font-size: 0.8rem;
    }
    #gameInvitesTitle{
        padding: 5px 10px;
        margin-top: 10px;
    }
    #playerBox{
        width: 70%;
    }
    #findMatchMenu{
        width: 15rem;
        height: 15rem;
    }
    /*Game*/
    #actionBox h2{
        font-size: 1.5rem;
        padding: 0;
    }
    .boardTitle{
        font-size: 2rem;
    }
    #boardBox{
        padding-bottom: 0rem;
    }
    #boardInnerBox{
        /* padding-top: 2rem; */
    }
    .boardInnerContainer{
        min-width:5rem;
        max-width: 8rem;
    }
    #leftBoard{
        min-height: 12rem;
        overflow-x: scroll;
        /* overflow-y: scroll; */
    }
    #rightBotBoard{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;
        margin: 0 auto;
        overflow-x:scroll;
        width: 90%;
    }
    .roleTitle{
        display:none !important;
    }
    #deckBox{
        display:none;
    }
    .currCharacterCardBox{
        /* width: 7rem; */
    }
    #infoInnerContainer{
        justify-content: left;
    }
    #infoGoldImage{
        width: 5rem;
    }
    #infoGoldContainer h2 {
        font-size: 2rem;
    }
    #goldImage{
        width: 5rem;
    }
    #goldValue{
        font-size: 1.25rem;
    }
    
    #districtDeckImage{
        width: 4rem;
    }
    
    #kingTokenImage{
        width: 4rem;
    }
    .toggleCharactersButton{
        font-size: 1rem;
    }
    #characterCardContainer{
        overflow: scroll;
    }
    .characterCard{
        width: 7rem;
    }
    #gameOptionsBox{
        padding: 1rem 0;
        margin-top: 0;
        background-color: rgb(219, 214, 214,0.8);
    }
    .arrowBoxLabels{
        display:none;
    }
    .colon{
        margin-left: 0.25rem;
    }
    #discardPileButton{
        display:none;
    }
    #turnTimerTitle{
        display:none;
    }
    #timerBoxGreen{
        min-width: 6rem;
    }

    #timerContainer{
        margin-top: 1rem;
    }
    
    #timerBoxYellow{
        min-width: 6rem;
    }
    
    #timerBoxRed{
        min-width: 6rem;
    }

    #cardInfoBox{
        width: 85%;
        height: 20rem;
        padding: 1rem;
    }
    .infoCard{
        width: 7rem !important;
    }
    #cardInfoTextBox{
        font-size: 0.9rem;
        width: 60%;
        font-weight: bold;
        padding: 2rem;
    }

    /*Results*/
    .resultsProperty{
        font-size: 2rem;
    }
    
  }

  @media screen and (max-width: 400px) {

    /*Landing*/
    .mainNav{
        padding: 0.25rem;    
    }
    #logoImg{
        width: 10rem;
    }
    .navbar{
        flex-direction: row;
    }
    .playButton{
        padding: 0.5rem !important;
        font-size: 1rem;
        margin-left: 0.5rem;
    }
    .rulesButton{
        padding: 0.5rem !important;
        font-size: 1rem;
        margin-left: 0.5rem;
    }
    #gameVideoTitle{
        margin-top: 1rem;
        margin-bottom: 2.5rem;
        font-size: 2.5rem;
    }
    #gamePlayVideoBox{
        text-align: center;
        background-image: url("/images/gameMenuBackground.jpeg");
        /* opacity: 0.7; */
    }
    
    #gamePlayVideo{
        width: 20rem;
        /* height: 37rem; */
        -webkit-box-shadow: 0px 0px 3px 3px #f2e1f2;
        -moz-box-shadow: 0px 0px 3px 3px #f2e1f2;
        box-shadow: 0px 0px 70px 70px #f2e1f2; 
        border-radius: 20px;
        margin-bottom: 5rem;
    }

    #discordIFrame{
        width: 20rem;
        height: 20rem;
    }

    .landingPageImages{
        width: 20rem;
    }

    #boardImage{
        display:none;
    }
    
    #bannerImageBox{
        display:none;
    }
    
    /* Menu */
    #gameMenuBackground{
        padding: 1rem;
    }
    #gameMenuContainer{
        width: 100%;
        padding: 1rem;
    }
    #gameMenuContainer h1{
        font-size: 2.5rem;
    }
    #playerBox{
        width: 70%;
    }
    #findMatchMenu{
        width: 15rem;
        height: 15rem;
    }
    /*Game*/
    #actionBox h2{
        font-size: 1.5rem;
        padding: 0;
    }
    .boardTitle{
        font-size: 2rem;
    }
    #boardBox{
        padding-bottom: 0rem;
    }
    #boardInnerBox{
        /* padding-top: 2rem; */
    }
    .boardInnerContainer{
        min-width:5rem;
        max-width: 8rem;
    }
    #leftBoard{
        min-height: 12rem;
        overflow-x: scroll;
        /* overflow-y: scroll; */
    }
    .roleTitle{
        display:none !important;
    }
    #deckBox{
        display:none;
    }
    .currCharacterCardBox{
        /* width: 7rem; */
    }
    #infoGoldImage{
        width: 5rem;
    }
    #infoGoldContainer h2 {
        font-size: 2rem;
    }
    #goldImage{
        width: 5rem;
    }
    #goldValue{
        font-size: 1.25rem;
    }
    
    #districtDeckImage{
        width: 4rem;
    }
    
    #kingTokenImage{
        width: 4rem;
    }
    .toggleCharactersButton{
        font-size: 1rem;
    }
    #characterCardContainer{
        overflow: scroll;
    }
    .characterCard{
        width: 7rem;
    }
    #gameOptionsBox{
        padding: 1rem 0;
        margin-top: 0;
        background-color: rgb(219, 214, 214,0.8);
    }
    .arrowBoxLabels{
        display:none;
    }
    .colon{
        margin-left: 0.25rem;
    }
    #discardPileButton{
        display:none;
    }
    #turnTimerTitle{
        display:none;
    }
    #timerBoxGreen{
        min-width: 6rem;
    }

    #timerContainer{
        margin-top: 1rem;
    }
    
    #timerBoxYellow{
        min-width: 6rem;
    }
    
    #timerBoxRed{
        min-width: 6rem;
    }
    #cardInfoBox{
        width: 90%;
        height: 20rem;
        padding: 1rem;
    }
    .infoCard{
        width: 7rem !important;
    }
    #cardInfoTextBox{
        font-size: 0.8rem;
        width: 60%;
        font-weight: bold;
        padding: 2rem;
    }
    #gameOptionsInnerBox{
       padding: 0px;
    }
    /*Results*/
    .resultsProperty{
        font-size: 2rem;
    }
    #actionBox{
        padding: 3.5rem 0 1rem 0;
    }
    
  }
